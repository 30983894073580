angular.module('app', ['ngRoute', 'ngCookies','ngResource', 'app.controllers','app.resources', 'app.services', 'app.directives']).run([
  '$rootScope', 'APIArticles', '$location', '$cookies', 'APICheckin', '$route'
  (root, APIArticles, $location, $cookies, APICheckin, $route) ->

    root.pageLoaded = false
    root.articles = undefined

    root.option = (name) ->
      return null unless $route.current?.$$route?.options?
      return null unless $route.current.$$route.options[name]?
      $route.current.$$route.options[name]

    root.at = (menu) ->
      root.menu is menu

    root.get = (param) ->
      $routeParams[param]

    root.$on '$routeChangeSuccess', ->
      root.menu = ''
      root.menu = root.option 'menu' if root.option 'menu'


    routeReload = false
    root.$on '$routeChangeStart', (event, next, current) ->      
      routeReload = false
      # wait loading required data and stop routing

      if !root.pageLoaded
        routeReload = true
        return event.preventDefault()

      return 

      nextPath = next?.$$route?.originalPath || '/logout'

      availablePath = ['/willkommen']

      if nextPath not in availablePath
        unless root.user?.auth_ticket
          event.preventDefault()
          return $location.path('/willkommen')

    root.session  = null
    root.$on 'proveSession', (event) -> 
      root.user = $cookies.getObject('user') || null

      locParams = $location.search()

      if locParams.auth_ticket
        root.user = 
          auth_ticket: locParams.auth_ticket
          role: 'exhibitor'

      unless root.user?.auth_ticket
        $route.reload() if routeReload
        root.pageLoaded = true

        APIArticles.query({type: 'articles', full: true}, (data) ->
          root.articles = data
          root.$emit('articlesLoaded', data)
        , (error) ->
          console.error(error)
        )

        return 

      APICheckin.get {auth_ticket: root.user.auth_ticket, _private: true}, (data) ->
        root.session = data

        root.user.shortcode = data.shortcode unless root.user.shortcode
        root.user.role      = data.role
        root.user.id        = data.user.id

        $cookies.putObject('user', root.user)

        root.pageLoaded = true

        APIArticles.query({type: 'articles', full: true, e_id: root.user.id}, (data) ->
          root.articles = data
          root.$emit('articlesLoaded', data)
        , (error) ->
          console.error(error)
        )

        return $route.reload() if routeReload
    
      , (errorData) ->
        $location.search('auth_ticket', null)
        root.logout()


    root.$emit 'proveSession'

    root.logout = () ->
 
      root.user       = null
      root.session    = null
      root.pageLoaded = true

      $cookies.remove 'user'

      $location.path('/logout')
      root.$applyAsync()


    

]).config(['$routeProvider', '$locationProvider', '$cookiesProvider', (r, l, co) ->
  now = new Date()
  co.defaults.expires = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15)



  l.html5Mode(true)
  l.hashPrefix('')

  r.when '/',
    controller: 'HomeCtrl'
    templateUrl: "html/home/home.html"
    options:
      menu: 'home'

  r.when '/willkommen',
    controller: 'EmptyCtrl'
    templateUrl: "html/empty.html"
    options:
      menu: 'empty'

  r.when '/merkliste',
    controller: 'MerklisteCtrl'
    templateUrl: "html/merkliste/merkliste.html"
    options:
      menu: 'merkliste'

  r.when '/merkliste/print',
    controller: 'MerklistePrintCtrl'
    templateUrl: "html/merkliste/merkliste-print.html"
    options:
      menu: 'merkliste'

  r.otherwise
    redirectTo: '/'
])

