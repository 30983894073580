var indexOf = [].indexOf;

angular.module('app', ['ngRoute', 'ngCookies', 'ngResource', 'app.controllers', 'app.resources', 'app.services', 'app.directives']).run([
  '$rootScope',
  'APIArticles',
  '$location',
  '$cookies',
  'APICheckin',
  '$route',
  function(root,
  APIArticles,
  $location,
  $cookies,
  APICheckin,
  $route) {
    var routeReload;
    root.pageLoaded = false;
    root.articles = void 0;
    root.option = function(name) {
      var ref,
  ref1;
      if (((ref = $route.current) != null ? (ref1 = ref.$$route) != null ? ref1.options : void 0 : void 0) == null) {
        return null;
      }
      if ($route.current.$$route.options[name] == null) {
        return null;
      }
      return $route.current.$$route.options[name];
    };
    root.at = function(menu) {
      return root.menu === menu;
    };
    root.get = function(param) {
      return $routeParams[param];
    };
    root.$on('$routeChangeSuccess',
  function() {
      root.menu = '';
      if (root.option('menu')) {
        return root.menu = root.option('menu');
      }
    });
    routeReload = false;
    root.$on('$routeChangeStart',
  function(event,
  next,
  current) {
      var availablePath,
  nextPath,
  ref,
  ref1;
      routeReload = false;
      if (!root.pageLoaded) {
        routeReload = true;
        return event.preventDefault();
      }
      return;
      nextPath = (next != null ? (ref = next.$$route) != null ? ref.originalPath : void 0 : void 0) || '/logout';
      availablePath = ['/willkommen'];
      if (indexOf.call(availablePath,
  nextPath) < 0) {
        if (!((ref1 = root.user) != null ? ref1.auth_ticket : void 0)) {
          event.preventDefault();
          return $location.path('/willkommen');
        }
      }
    });
    root.session = null;
    root.$on('proveSession',
  function(event) {
      var locParams,
  ref;
      root.user = $cookies.getObject('user') || null;
      locParams = $location.search();
      if (locParams.auth_ticket) {
        root.user = {
          auth_ticket: locParams.auth_ticket,
          role: 'exhibitor'
        };
      }
      if (!((ref = root.user) != null ? ref.auth_ticket : void 0)) {
        if (routeReload) {
          $route.reload();
        }
        root.pageLoaded = true;
        APIArticles.query({
          type: 'articles',
          full: true
        },
  function(data) {
          root.articles = data;
          return root.$emit('articlesLoaded',
  data);
        },
  function(error) {
          return console.error(error);
        });
        return;
      }
      return APICheckin.get({
        auth_ticket: root.user.auth_ticket,
        _private: true
      },
  function(data) {
        root.session = data;
        if (!root.user.shortcode) {
          root.user.shortcode = data.shortcode;
        }
        root.user.role = data.role;
        root.user.id = data.user.id;
        $cookies.putObject('user',
  root.user);
        root.pageLoaded = true;
        APIArticles.query({
          type: 'articles',
          full: true,
          e_id: root.user.id
        },
  function(data) {
          root.articles = data;
          return root.$emit('articlesLoaded',
  data);
        },
  function(error) {
          return console.error(error);
        });
        if (routeReload) {
          return $route.reload();
        }
      },
  function(errorData) {
        $location.search('auth_ticket',
  null);
        return root.logout();
      });
    });
    root.$emit('proveSession');
    return root.logout = function() {
      root.user = null;
      root.session = null;
      root.pageLoaded = true;
      $cookies.remove('user');
      $location.path('/logout');
      return root.$applyAsync();
    };
  }
]).config([
  '$routeProvider',
  '$locationProvider',
  '$cookiesProvider',
  function(r,
  l,
  co) {
    var now;
    now = new Date();
    co.defaults.expires = new Date(now.getFullYear(),
  now.getMonth(),
  now.getDate() + 15);
    l.html5Mode(true);
    l.hashPrefix('');
    r.when('/',
  {
      controller: 'HomeCtrl',
      templateUrl: "html/home/home.html",
      options: {
        menu: 'home'
      }
    });
    r.when('/willkommen',
  {
      controller: 'EmptyCtrl',
      templateUrl: "html/empty.html",
      options: {
        menu: 'empty'
      }
    });
    r.when('/merkliste',
  {
      controller: 'MerklisteCtrl',
      templateUrl: "html/merkliste/merkliste.html",
      options: {
        menu: 'merkliste'
      }
    });
    r.when('/merkliste/print',
  {
      controller: 'MerklistePrintCtrl',
      templateUrl: "html/merkliste/merkliste-print.html",
      options: {
        menu: 'merkliste'
      }
    });
    return r.otherwise({
      redirectTo: '/'
    });
  }
]);
